import { reactive, defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";
import { Field, Form, useForm, useField } from "vee-validate";
import * as yup from "yup";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import ForgotPasswordPopup from "@/presentation/components/modals/organization-forgot-password/OrganizationForgotPassword.vue";
import SendmMailForgotPasswordPopup from "@/presentation/components/modals/organization-sendmail-reset-password/OrganizationSendMailForgotPassword.vue";
import LanguageUtil from "@/commons/LanguageUtil";
import AuthInteractor from "@/domain/usecases/AuthInteractor";
import RoutePath from "@/commons/RoutePath";
import DIContainer from "@/core/DIContainer";
import { useStore } from "vuex";
import * as agh from "agh.sprintf";
import Constant from "@/commons/Constant";
import Input from "@/presentation/components/input/Input.vue";
export default defineComponent({
    name: "CompanyLogin",
    components: {
        Field: Field,
        Form: Form,
        ErrorMessage: ErrorMessage,
        ForgotPasswordPopup: ForgotPasswordPopup,
        SendmMailForgotPasswordPopup: SendmMailForgotPasswordPopup,
        Breadcrumb: Breadcrumb,
        Input: Input
    },
    setup: function () {
        var router = useRouter();
        var route = useRoute();
        var store = useStore();
        var authInteractor = DIContainer.instance.get(AuthInteractor);
        /* eslint-disable @typescript-eslint/camelcase */
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            listBreadcrumb: [
                {
                    route: RoutePath.TOP, name: "TOP"
                },
                {
                    route: '', name: "企業会員ログイン"
                }
            ],
            listBlacklistBackUrl: [
                RoutePath.LOGIN,
                RoutePath.ORGANIZATION_LOGIN,
                RoutePath.USER_REGISTER,
                RoutePath.USER_REGISTER_PROFILE,
                RoutePath.USER_REGISTER_CONFIRM,
                RoutePath.USER_REGISTER_COMPLETED,
                RoutePath.ORGANIZATION_REGISTER,
                RoutePath.ORGANIZATION_REGISTER_DETAIL,
                RoutePath.ORGANIZATION_RESET_PASSWORD,
                RoutePath.ORGANIZATION_RESET_PASSWORD_SUCCESS,
                RoutePath.ORGANIZATION_DASHBOARD,
                RoutePath.ORGANIZATION_REGISTER_EXPIRED,
                RoutePath.ENGINEER_REGISTER
            ],
            errorLoginMessage: null,
            errorLoginValidation: null,
            showSendMailForgotPassword: false,
            showForgotPassword: false
        });
        var _a = useForm({
            initialValues: {
                email: "",
                password: ""
            }
        }), isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit, setErrors = _a.setErrors, setFieldError = _a.setFieldError, setValues = _a.setValues, errors = _a.errors, values = _a.values;
        var email = useField("email", yup.string()
            .required(state.aLang.validation_required)
            .email(state.aLang.validation_format_email)
            .max(255, agh.sprintf(state.aLang.validation_max_length, 255))).value;
        var password = useField("password", yup.string()
            .required(state.aLang.validation_required)
            .min(8, agh.sprintf(state.aLang.validation_min_length, 8))
            .max(20, agh.sprintf(state.aLang.validation_max_length, 20))
            .matches(Constant.PASSWORD_PATTERN, state.aLang.validation_format_password)).value;
        function openSendMailPopup() {
            state.showForgotPassword = false;
            state.showSendMailForgotPassword = true;
        }
        var onSubmit = handleSubmit(function (values) {
            state.errorLoginMessage = null;
            return authInteractor
                .organizationLogin(values.email, values.password, false)
                .then(function (result) {
                return authInteractor.authCheck().then(function (result) {
                    if (result.data &&
                        result.data.user &&
                        (result.data.user.name ||
                            result.data.user.nick_name)) {
                        var account_name = result.data.user.name;
                        var is_composition = result.data.user.is_composition;
                        localStorage.setItem("setIsloginStatus", account_name);
                        localStorage.setItem("accountName", account_name);
                        localStorage.setItem("isCompositon", is_composition);
                        store.commit("setHeaderMode", "organizationLogged");
                        store.commit("setIsloginStatus", true);
                        var callBackUrl = store.state.callBackUrl;
                        if (callBackUrl) {
                            router.push(callBackUrl);
                            store.commit("setCallBackUrl", null);
                            return;
                        }
                        if (state.listBlacklistBackUrl.includes(window.history.state.back) ||
                            window.history.state.back == null) {
                            if (result.data.user.is_composition === true) {
                                router.push(RoutePath.SELECT_ORGANIZATION);
                            }
                            else {
                                router.push(RoutePath.ORGANIZATION_DASHBOARD);
                            }
                        }
                        else {
                            if (result.data.user.is_composition === true) {
                                router.push(RoutePath.SELECT_ORGANIZATION);
                            }
                            else {
                                router.push(window.history.state.back);
                            }
                        }
                    }
                });
            })
                .catch(function (error) {
                state.errorLoginMessage = error.message;
                setValues({
                    password: ""
                });
                setTimeout(function () {
                    setFieldError("password", "");
                }, 1);
            });
        });
        return {
            state: state,
            openSendMailPopup: openSendMailPopup,
            onSubmit: onSubmit,
            isSubmitting: isSubmitting,
            errors: errors,
            values: values
        };
    }
});
