import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { Field, Form } from "vee-validate";
import * as yup from "yup";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import AuthInteractor from "@/domain/usecases/AuthInteractor";
import DIContainer from "@/core/DIContainer";
import * as agh from "agh.sprintf";
export default defineComponent({
    name: "ForgotPasswordPopup",
    components: { Field: Field, Form: Form, ErrorMessage: ErrorMessage },
    props: ["openSendMailPopup"],
    setup: function (props, _a) {
        var emit = _a.emit;
        var router = useRouter();
        var authInteractor = DIContainer.instance.get(AuthInteractor);
        /* eslint-disable @typescript-eslint/camelcase */
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            errorResendResetEmailMessage: null,
            errorLoginValidation: null
        });
        var schema = yup.object().shape({
            email: yup
                .string()
                .required(state.aLang.validation_required)
                .email(state.aLang.validation_format_email)
                .max(255, agh.sprintf(state.aLang.validation_max_length, 255))
        });
        function onSubmit(values) {
            state.errorResendResetEmailMessage = null;
            return authInteractor
                .organizationSendMailResetPassword(values.email)
                .then(function (result) {
                emit("openSendMailPopup");
            }, function (error) {
                state.errorResendResetEmailMessage = error.message;
            });
        }
        return {
            state: state,
            schema: schema,
            onSubmit: onSubmit
        };
    }
});
