<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-body">
                        <a @click="$emit('close')" class="modal-close-btn"
                            >✕ 閉じる</a
                        >
                        <slot name="body">
                            <div class="forgotPassword__container">
                                <div class="forgotPassword__container-title">
                                    パスワードを忘れた方
                                </div>
                                <p class="forgotPassword__container-sub_title">
                                    会員ID（メールアドレス）を入力してください。<br />
                                    メールアドレス宛にパスワード再設定用URLを送信します。
                                </p>
                                <Form
                                    class="horizontal"
                                    @submit="onSubmit"
                                    v-slot="{ errors, isSubmitting }"
                                    :validation-schema="schema"
                                >
                                    <div class="form-ctrl">
                                        <label for="email" class="text"
                                            >会員メールアドレス</label
                                        >
                                        <div class="form-group">
                                            <Field
                                                maxlength="255"
                                                id="email"
                                                type="email"
                                                name="email"
                                                as="input"
                                                placeholder="入力してください"
                                            />
                                            <ErrorMessage
                                                v-if="errors.email"
                                                :message="errors.email"
                                            />
                                            <ErrorMessage
                                                v-if="
                                                    state.errorResendResetEmailMessage
                                                "
                                                :message="
                                                    state.errorResendResetEmailMessage
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div
                                        class="forgotPassword__container-button"
                                    >
                                        <button
                                            :disabled="isSubmitting"
                                            :class="{
                                                submitting: isSubmitting
                                            }"
                                            type="submit"
                                            class="btn-lg sweep-to-top"
                                        >
                                            <i
                                                v-if="isSubmitting"
                                                class="fas fa-circle-notch fa-spin"
                                            ></i>
                                            再設定URLを送信
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" src="@/presentation/components/modals/organization-forgot-password/OrganizationForgotPassword.ts" />
<link type="scss" scoped src="@/presentation/components/modals/organization-forgot-password/OrganizationForgotPassword.scss" />
