import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
    name: "ForgotPasswordPopup",
    setup: function (props, _a) {
        var emit = _a.emit;
        var router = useRouter();
        /* eslint-disable @typescript-eslint/camelcase */
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            errorResendResetEmailMessage: null,
            errorLoginValidation: null
        });
        return {
            state: state
        };
    }
});
