<template>
    <div>
        <div class="comLogin short-container">
            <Breadcrumb :breadcrumb="state.listBreadcrumb" />
            <div class="comLogin__container">
                <div class="comLogin__container-title">
                    企業アカウントログイン
                </div>
                <p class="comLogin__container-sub_title">
                    ログイン用のアカウント情報をご入力ください。
                </p>
                <Form
                    class="horizontal"
                    @submit="onSubmit"
                >
                    <div class="form-ctrl">
                        <label for="email" class="text">メールアドレス</label>
                        <div class="form-group">
                            <Input
                                maxlength="255"
                                id="email"
                                type="email"
                                name="email"
                                v-model="values.email"
                                placeholder="入力してください"
                            />
                            <ErrorMessage
                                v-if="errors.email"
                                :message="errors.email"
                            />
                        </div>
                    </div>

                    <div class="form-ctrl">
                        <label for="password" class="text">パスワード</label>
                        <div class="form-group">
                            <Input
                                maxlength="20"
                                id="password"
                                type="password"
                                name="password"
                                v-model="values.password"
                                placeholder="入力してください"
                            />

                            <ErrorMessage
                                v-if="errors.password"
                                :message="errors.password"
                            />
                            <ErrorMessage
                                v-if="state.errorLoginMessage"
                                :message="state.errorLoginMessage"
                            />
                        </div>
                    </div>

                    <div class="comLogin__container-button">
                        <button
                            :disabled="isSubmitting"
                            :class="{ submitting: isSubmitting }"
                            type="submit"
                            class="btn-lg sweep-to-top"
                        >
                            <i
                                v-if="isSubmitting"
                                class="fas fa-circle-notch fa-spin"
                            ></i>
                            ログイン
                        </button>
                    </div>
                    <a
                        href="#"
                        @click="state.showForgotPassword = true"
                        class="comLogin__container-forgot"
                    >
                        パスワードを忘れた方はこちら
                    </a>
                </Form>
            </div>
            <ForgotPasswordPopup
                v-if="state.showForgotPassword"
                @close="state.showForgotPassword = false"
                @openSendMailPopup="openSendMailPopup"
            />
            <SendmMailForgotPasswordPopup
                v-if="state.showSendMailForgotPassword"
                @close="state.showSendMailForgotPassword = false"
            />
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/organization/login/OrganizationLogin.ts"></script>

<style lang="scss" scoped>
@import "@/presentation/views/organization/login/OrganizationLogin.scss";
</style>
