<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-body">
                        <a @click="$emit('close')" class="modal-close-btn"
                            >✕ 閉じる</a
                        >
                        <slot name="body">
                            <div class="forgotPassword__container">
                                <div class="forgotPassword__container-title">
                                    メールをご確認ください
                                </div>
                                <p class="forgotPassword__container-sub_title">
                                    ご登録されたメールアドレス宛にパスワード再設定URLを送信しました。<br />
                                    再設定URLの有効期限は24時間です<br />
                                    24時間を経過しますと再度送信して頂く必要がありますので、それまでにご確認ください。
                                </p>
                                <form action="">
                                    <div
                                        class="forgotPassword__container-button"
                                    >
                                        <button
                                            type="button"
                                            class="btn-lg sweep-to-top"
                                            @click="$emit('close')"
                                        >
                                            閉じる
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" src="@/presentation/components/modals/organization-sendmail-reset-password/OrganizationSendMailForgotPassword.ts" />
<link type="scss" scoped src="@/presentation/components/modals/organization-sendmail-reset-password/OrganizationSendMailForgotPassword.scss" />
